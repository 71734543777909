<template>
<div>
    <v-container>

        <h1 class="text-center mb-10 page_title">施設案内</h1>
        <div class="text-center">
            <p class="f-12 f-10-xs mb-10 text-center">宿泊はもちろん、日帰り利用もできる<br>
                広々とした完全フリースペースのオートキャンプ場です</p>
            <img :src="'../imgs/map.png'" class="map mx-auto">

            <v-card class="terms px-4 py-5 pt-8 mx-auto mt-10 f-12 f-10-xs f-09-xxs">
                <div class="inline-block ">
                    <h3 class="f-gray">【オンシーズン】</h3>
                    <ul class="mt-3">
                        <li v-html="text" v-for="text in texts1" :key="text" style="word-break:break-all;" />
                    </ul>
                    <h3 class="f-gray mt-10">【設備の概要】</h3>
                    <ul class="mt-3">
                        <li v-html="text" v-for="text in texts2" :key="text" style="word-break:break-all;" />
                    </ul>
                    <h3 class="f-gray mt-10">【利用上の注意】</h3>
                    <ul class="mt-3">
                        <li v-html="text" v-for="text in texts3" :key="text" style="word-break:break-all;" />
                    </ul>
                </div>
            </v-card>

            <v-row class="mx-auto mt-16 photo_box">
                <v-col cols="12" xl="4" lg="4" md="6" sm="6">
                    <iframe class="photo my-6" src="https://www.youtube.com/embed/z2Qcr9aLvMg?loop=1&playlist=z2Qcr9aLvMg&rel=0&autoplay=1&mute=1&modestbranding=1&showinfo=0" title="YouTube video player" frameborder="0"></iframe>
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="6" sm="6" v-for="i in 5" :key="i">
                    <img :src="photos[i-1]" class="photo my-6 pointer" @click="dialogOpen(photos[i-1])">
                </v-col>
            </v-row>
        </div>
    </v-container>
    <v-dialog v-model="dialog" width="1000">
        <img :src="img" class="width-100 dialog_photo" v-if="img" />
    </v-dialog>
</div>
</template>

<script>
import axios from "axios"

export default {
    data() {
        return {
            load: false,
            texts1: [],
            texts2: [],
            texts3: [],
            dialog: false,
            img: "",
            url: '../storage/imgs/',
            photos: [],

        }
    },
    async mounted() {
        const response = await axios.post('/master/tearm')
        for (let i = 1; i < 4; i++) {
            this['texts' + i] = response.data['texts' + i];
        }
        const respons = await axios.post('/master/photo_load')
        this.photos = respons.data.photos.map(item => this.url + item.name)

    },
    methods: {
        dialogOpen(val) {
            this.img = val
            this.dialog = true
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/facility.scss";
</style>
